.bio-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px;
  padding: 20px;
  border: 0px solid #ddd;
  max-width: 1100px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.5;
}
