.certification-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
  align-items: flex-end;
  background: none;
  width: 100%;
  margin-bottom: 20px;
}

.certification-box {
  display: flex;
  border: 0px solid #444;
  padding: 20px;
  border-radius: 12px;
  gap: 20px;
  align-items: flex-start;
  width: 50%;
  background: rgba(16, 14, 27, 0.3);
  transition:
    box-shadow 0.3s ease,
    transform 0.3s ease;
  text-decoration: none;
  color: white;
}

.certification-box:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  background: #100e1b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.certification-box h4 {
  margin: 0;
  flex-grow: 1;
}

.certification-date {
  color: #a39ba1;
  font-size: 0.85em;
  white-space: nowrap;
  align-self: center;
}

@media (max-width: 1660px) {
  .certification-list {
    align-items: center;
  }

  .certification-box {
    width: 80%;
  }
}
