.work-experience-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;
}

.work-experience-box {
  display: flex;
  border: 0px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  gap: 20px;
  align-items: flex-start;
  width: 70%;
  transition: background-color 0.3s ease;
}

.work-experience-box:hover {
  background-color: #100e1b;
}

.work-duration {
  color: #a39ba1;
  flex-basis: 30%;
  flex-shrink: 0;
  align-self: flex-start;
  font-size: 0.9em;
}

.work-details {
  flex-grow: 1;
  text-align: left;
  align-self: flex-start;
  margin-top: 0;
}

.work-details h3 {
  margin-top: 0;
}

@media (max-width: 1660px) {
  .work-experience-list {
    align-items: center;
  }

  .work-experience-box {
    width: 80%;
    flex-direction: column;
  }
  .work-experience-box:hover {
    background-color: transparent;
  }
}
