.navbar {
  background-color: transparent;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: background-color 0.3s;
  z-index: 10;
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0);
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

.nav-item {
  margin: 0 10px;
}

.nav-link {
  display: block;
  padding: 14px;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s;
}

.nav-link:hover {
  background-color: #555;
}

@media (max-width: 768px){
  .navbar-nav{
    justify-content: center;
  }
}

@media (max-width: 1660px) {
  .navbar.scrolled {
    background-color: rgba(0, 0, 0, 100);
  }
}
