.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 150px;
  background: transparent;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  max-width: 700px;
}

.name {
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 1.2;
}

.role {
  margin: 0.2em 0;
  font-size: 2.2rem;
  line-height: 1.2;
}

.tagline {
  margin-top: 0.4em;
  font-size: 1.2rem;
  line-height: 1.2;
  width: 65%;
  white-space: pre-wrap;
  color: #a39ba1;
}

@media (max-width: 1660px) {
  .header {
    margin-top: 30px;
  }
}
